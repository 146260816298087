import { getUrl } from 'aws-amplify/storage';
import { getCurrentUser } from 'aws-amplify/auth';

export async function getSignedURL(filename) {
  try {
    const { identityId } = await getCurrentUser();

    console.log(identityId)

    const signedURL = await getUrl({
      path: filename,
      options: {
        level: 'protected',
        identityId: identityId,
        expires: 60,
        validateObjectExistence: true
      }
    });
    return signedURL.url;
  } catch (error) {
    console.error('Error getting signed URL', error);
    throw error;
  }
}