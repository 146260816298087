import React from "react";

const SignOut = ({ signOut }) => {
  return (
    <button
      onClick={signOut}
      className="w-full sm:w-[1/2 - 16px] py-2 px-4 rounded-md mt-4 bg-white text-blue-500 border-blue-500 hover:brightness-90 border-solid border font-bold inline-block"
    >
      Sign out
    </button>
  );
};

export default SignOut;