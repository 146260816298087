import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

import SignOut from './entities/button';
import DownloadButton from './entities/download-button';
import DateSelect from './entities/date-select';
Amplify.configure(awsExports);

export default function App() {

  const today = new Date();
  
  const [selectedDate, setSelectedDate] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const [error, setError] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setError(null);
  };

  return (
    <Authenticator
      components={{
        SignIn: {
          Header() {
            return (
              <>
                <h1 className='mt-4 font-bold text-2xl'>Sign in to your account</h1>
              </>
            ) 
            ;
          },
          Footer() {
            return null;
          }
        }
      }}
      hideSignUp={true}
      className=''
    >
      {({ signOut }) => (
        <main>
          <DateSelect onDateChange={handleDateChange} initialDate={selectedDate}/>
          <div className='h-6'>
            {error && <p className="text-red-500 text-center my-4">{error}</p>}
          </div>
          <div className='w-[100% - 16px] sm:w-1/2 mx-4 sm:mx-auto flex flex-col sm:flex-row sm:gap-4 justify-between'>
            <DownloadButton selectedDate={selectedDate} setError={setError} />
            <SignOut signOut={signOut} />
          </div>
        </main>
      )}
    </Authenticator>
  );
}