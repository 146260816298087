import React, { useState, useEffect } from 'react';

const DateSelect = ({ onDateChange, initialDate }) => {
  const minYear = 2024;
  const minMonth = 11;
  const minDay = 28;

  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: currentYear - minYear + 1 }, (_, i) => minYear + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const [selectedYear, setSelectedYear] = useState(initialDate.year);
  const [selectedMonth, setSelectedMonth] = useState(initialDate.month);
  const [selectedDay, setSelectedDay] = useState(initialDate.day);

  useEffect(() => {
    onDateChange({ year: selectedYear, month: selectedMonth, day: selectedDay });
  }, [selectedYear, selectedMonth, selectedDay]);

  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value);
    setSelectedYear(newYear);
    onDateChange({ year: newYear, month: selectedMonth, day: selectedDay });
  };

  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value);
    setSelectedMonth(newMonth);
    onDateChange({ year: selectedYear, month: newMonth, day: selectedDay });
  };

  const handleDayChange = (e) => {
    const newDay = parseInt(e.target.value);
    setSelectedDay(newDay);
    onDateChange({ year: selectedYear, month: selectedMonth, day: newDay });
  };

  const getFilteredMonths = () => {
    if (selectedYear === minYear) {
      return months.filter(month => month >= minMonth);
    }
    return months;
  };

  const getFilteredDays = () => {
    if (selectedYear === minYear && selectedMonth === minMonth) {
      return days.filter(day => day >= minDay);
    }
    return days;
  };

  return (
    <div className="w-[100% - 16px] sm:w-1/2 mx-4 sm:mx-auto my-12 p-6 text-center bg-slate-300 rounded-lg flex align justify-center gap-4">
      <select value={selectedYear} onChange={handleYearChange} className='text-2xl'>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <p className='inline-block m-2'>年</p>
      <select value={selectedMonth} onChange={handleMonthChange} className='text-2xl'>
        {getFilteredMonths().map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
      <p className='inline-block m-2'>月</p>
      <select value={selectedDay} onChange={handleDayChange} className='text-2xl'>
        {getFilteredDays().map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
      <p className='inline-block m-2'>日</p>
    </div>
  );
}

export default DateSelect;