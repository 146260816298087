import { getSignedURL } from '../utils/storageUtils';

const DownloadButton = ({ selectedDate, setError }) => {
  const handleDownload = async () => {
    const { year, month, day } = selectedDate;
    const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const filePath = `download/${formattedDate}.csv`;

    console.log(filePath);

    try {
      const signedURL = await getSignedURL(filePath);
      console.log('Signed URL:', signedURL);
      const link = document.createElement('a');
      link.href = signedURL;
      link.download = `${formattedDate}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setError(null);
    } catch (error) {
      console.error('Error downloading the file', error);
      setError('CSVファイルが見つかりません。');
    }
  };


  return (
    <button
      className="w-full sm:w-[1/2 - 16px] py-2 px-4 rounded-md mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold inline-block"
      onClick={handleDownload}
    >
      CSV ダウンロード
    </button>
  )
}

export default DownloadButton